.custom-modal .modal-content {
  background-color: rgba(255, 255, 255, 0.13); /* Adjust transparency here */
  border: none;
  box-shadow: none;
    backdrop-filter: blur(10px); /* Blur effect */

}

.custom-modal .modal-header,
.custom-modal .modal-body,
.custom-modal .modal-footer {
  background-color: transparent;
}

.custom-modal .modal-header {
  border-bottom: none;
}

.custom-modal .modal-footer {
  border-top: none;
}