.navbar {
  background-color: transparent !important;
}

.navbar-light .navbar-nav .nav-link {
  color: white !important;
  font-weight: bold;
  text-decoration: underline;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: white !important;
  text-decoration: underline;
}

.custom-mr-auto {
  margin-right: 20px;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 93vh;
}

body {
  scroll-behavior: smooth;
}

@keyframes rotate360 {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

.footer {
  margin-top: -51px;
  text-align: center;
  color: white !important;
}

.custom-navbar {
  margin-left: 3%;
  color: white;
}

.h1, .h2, .h3, .h4, .h5, .h6 , .h7 ,.p , .ul , .li , .strong , .hr , #text , h1, h2, h3, h4, h5, h6, h7 , p , ul , li , strong , hr , text{
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: white !important;
}

.dropdown{
  top: -18%;
  bottom: 0%;
  right: 0%;
  left: -1.5%;

}
.dropdown2{
  top: -18%;
  bottom: 0%;
  right: 0%;
  left: -1.5%;

}


 .logo {    
margin-top: 46%;
    margin-left: 33%;
    margin-right: 0%;
    margin-bottom: 0%;
}

.why {
    margin-top: -35%;
    margin-left: 0%;
}

.dropdown-item-custom {
  font-weight: bold;
  color: white;
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.dropdown-item-custom:hover {
  background-color: #D4AF37; /* Light blue background on hover */
}



/* Media queries for different screen sizes */
/* Laptop  */

@media (max-width: 1366px) {
.logo {
        margin-top: 30%;
        margin-left: 33%;
        margin-right: 0%;
        margin-bottom: 0%;

}

.why{
       margin-top: -30%;
        margin-left: -9%;
}

.why2{
        margin-top: -14%;
        margin-left: -9%;
}

.undercst {
        margin-top: -15%;
        margin-left: 44%;
        margin-right: 0%;
        margin-bottom: 0%;
    }

    .contact{
      margin-top: 16%;
    }
  }

  /* For Small Laptop */

  @media (max-width: 1326px) {
.logo {
        margin-top: 35%;
        margin-left: 35%;
        margin-right: 0%;
        margin-bottom: 0%;
    }
.undercst {
        margin-top: -13%;
        margin-left: 44%;
        margin-right: 0%;
        margin-bottom: 0%;
    }
    .why{
        margin-top: -34%;
        margin-left: -4%;
}
    .why2{
        margin-top: -14%;
        margin-left: -9%;
}

.contact{
      margin-top: 12%;
    }
  }


  /* For Typical Smartphone ( Redmi Note 10 ) */

  @media (max-width: 398px) {
    body {
      margin: 0;
      padding: 0;
      overflow: auto;
    }
    
.why{
        margin-top: -76%;
        margin-left: 1%;
}
.why2{
        margin-top: -19%;
        margin-left: 1%;
}

  img {
    width: 100%;
    height: auto;
  }

    .logo {
        margin-top: 26%;
        margin-left: 34%;
        margin-right: 0%;
        margin-bottom: 0%;
    }
     
    .undercst {
        margin-top: -8%;
        margin-left: 39%;
        margin-right: 0%;
        margin-bottom: 0%;
    }

  }

/* Desktop */ 

 @media (min-width: 1920px) {
.logo {
        margin-top: 31%;
        margin-left: 25%;
        margin-right: 0%;
        margin-bottom: 0%;
    }
    .why{
        margin-top: -23%;
        margin-left: -25%;
}
  
    .why2{
        margin-top: -9%;
        margin-left: -25%;
}

    .undercst {
        margin-top: -8%;
        margin-left: 50%;
        margin-right: 0%;
        margin-bottom: 0%;
    }

    .contact{
      margin-top: 20%;
    }
  }

  @media print {
    /* Set the page to landscape mode */
    @page {
      size: A4 landscape;
      margin: 0;
    }

    /* Adjust the content layout for landscape */
    body {
      width: 100%;
      margin: 0;
    }
.splash-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #555; /* Adjust background color */
}

.splash-screen img {
  max-width: 100%;
  max-height: 100%;
}
  }