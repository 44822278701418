body {
  background: linear-gradient(
    45deg,
    #111,
    #222,
    #444,
    #555
  );
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
/* .night {
  background-color: #0f0c29;
  color: #fff;
} */
